import React, {useEffect} from 'react';

const Privacy = () => {

    useEffect(() => {
        document.title = 'Սովորել Անգլերեն - Политика конфиденциальности';
    }, []);

    return <div className="container pt-5">
        <h1>Սովորել Անգլերեն</h1>
        <h2>Политика конфиденциальности</h2>

        <p>Սովորել Անգլերեն(«Սովորել Անգլերեն», «мы», «наш» или «нас») стремится защищать права на конфиденциальность
            своих пользователей («пользователи» или «вы»). Настоящая Политика конфиденциальности (далее «Политика»)
            описывает способы сбора, хранения, использования и управления информацией, включая личную информацию и
            данные, которые вы предоставляете или мы собираем в связи с нашим приложением Սովորել Անգլերեն на мобильной
            платформе (например, iOS или Android) (совместно именуемые «Услуга(-и)»). Обратите внимание, что сфера
            действия настоящей Политики ограничена информацией и данными, собранными или полученными Սովորել Անգլերեն
            при
            использовании вами Услуги. Սովորել Անգլերեն не несет ответственности за действия третьих лиц или компаний,
            содержание их сайтов, использование информации или данных, которые вы им предоставляете, а также любые
            продукты или услуги, которые они могут предложить. Любая ссылка на такие сайты не подразумевает наше
            спонсорство или партнерство с этими лицами или компаниями.

            Используя Услугу, вы выражаете свое согласие с настоящей Политикой и обработкой вашей информации и данных,
            включая вашу личную информацию, в порядке, предусмотренном настоящей Политикой. Если вы не согласны с этими
            условиями, не пользуйтесь этой Услугой.

            Если у вас возникнут вопросы или сомнения, свяжитесь с нами по адресу, представленному в разделе Контактная
            информация контроллера данных настоящей Политики.</p>

        <h3>Информация и данные о вас, которые мы собираем</h3>
        <p>Мы можем собирать следующую информацию и данные о вас:

            Контактная информация (имя и адрес электронной почты)

            Имя пользователя и пароль для доступа к нашему форуму

            Информация для профиля пользователя, которую вы предоставляете добровольно (например, фото, пол, возраст или
            дата рождения, ссылки на профили на сайтах других социальных сетей)

            Автоматически собираемые данные (такие как IP-адрес, операционная система вашего устройства, тип браузера и
            язык)

            Идентификаторы мобильных устройств (например, уникальный идентификатор устройства (постоянный /
            непостоянный), тип аппаратного обеспечения, адрес управления доступом к среде (MAC-адрес), международный
            идентификатор мобильного оборудования (IMEI) и имя вашего устройства)

            Общая информация о местоположении для предоставления вам правильной версии приложения

            Информация о точном географическом местоположении с вашего мобильного устройства (но только с вашего
            согласия)

            Данные приложения (например, ваши взаимодействия с приложением с помощью лог-файлов сервера, ваш
            идентификатор пользователя)

            Технологии отслеживания

            Мы сами и наши партнеры по маркетингу и аутсорсингу, аффилированные партнеры или поставщики услуг аналитики
            используем такие технологии, как файлы cookie, веб-маяки, скрипты и тэги, чтобы идентифицировать устройство
            пользователя и «запоминать» сведения о вашем посещении, например, ваши предпочтения или имя пользователя и
            пароль. Информация, содержащаяся в файле cookie, может быть связана с вашей личной информацией, такой как
            ваш идентификатор пользователя, в целях улучшения качества наших Услуг, составления рекомендаций в
            соответствии с вашими интересами и упрощения использования Услуги. Вы можете отключить файлы cookie в любое
            время, однако, после этого, возможно, вам не удастся получить доступ к Услуге или использовать ее
            возможности.

        </p>


        <h4>Как мы используем вашу информацию</h4>
        <p>Компания Սովորել Անգլերեն использует информацию прежде всего в целях создания вашей учётной записи,
            предоставления вам Услуг, усовершенствования нашей Услуги, поддержания связи с вами, проведения
            маркетинговых исследований и составления отчетов для внутреннего пользования. Мы храним информацию на
            серверах, расположенных в Amazon.com и могут хранить информацию на серверах и оборудовании в других странах.

            Собранную через нашу Услугу информацию мы используем в целях, которые описаны в этой Политике и раскрыты вам
            в связи с нашей Услугой. Например, мы можем использовать вашу информацию в таких целях:

            Создавать учетные записи и предоставлять пользователям возможность использовать наше приложение;

            Осуществлять и улучшать нашу Услугу;

            Лучше понять ваши интересы и предпочтения, чтобы предложить вам расширенные возможности и доставить
            удовольствие от использования нашей Услуги;

            Отвечать на ваши комментарии или вопросы и обеспечивать обслуживание клиентов;

            Предоставлять и доставлять товары и услуги по вашему запросу;

            Доставлять вам рекламную, маркетинговую информацию и информацию об акциях;

            Отправлять вам соответствующую информацию, включая подтверждения, инвойсы, технические уведомления,
            обновления, предупреждения системы безопасности, а также сообщения службы поддержки и администрирования;

            Сообщать вам об акциях, наградах, предстоящих событиях и других новостях о продуктах и услугах, предлагаемых
            Սովորել Անգլերեն и нашими избранными партнерами;

            Связывать или совмещать ее с другой информацией, которую мы получаем от третьих лиц, чтобы помочь нам понять
            ваши предпочтения и предоставлять вам лучшие услуги.</p>

        <h4>
            Обслуживание клиентов


        </h4>
        <p>
            Когда вы обращаетесь в нашу службу поддержки клиентов, мы можем использовать ваш адрес электронной почты для
            связи с вами, чтобы спросить вас о ваших впечатлениях от приложения Սովորել Անգլերեն и сообщить вам о
            новостях
            компании и специальных предложениях.

            Если вы больше не желаете получать какие-либо электронные сообщения, вы можете в любое время отказаться от
            них. Для этого перейдите по ссылке отказа от подписки, расположенной внизу каждого сообщения, или отправьте
            нам электронное письмо, используя контактную информацию, предоставленную в этой политике.

            Ваш почтовый адрес может быть использован, если мы захотим отправить вам сувенирную продукцию или другие
            рекламные материалы. Если вы больше не хотите получать такие рекламные материалы, вы можете отказаться от их
            получения в любое время, отправив нам электронное письмо, используя контактную информацию, предоставленную в
            этой политике.


        </p>
        <h4>Услуги, предоставляемые третьей стороной

        </h4>
        <p>
            Наши Услуги могут содержать сторонние средства отслеживания, сбора данных и аналитики от наших поставщиков
            услуг, например, google analytics и встроенные инструменты google play и apple store Такие третьи стороны
            могут использовать файлы cookies, API и SDK в рамках нашей Услуги, которые позволяют им от нашего имени
            собирать и анализировать данные и информацию, связанные с пользователем и устройством. Третьи стороны могут
            получать доступ к следующим данным и информации о вас и собирать их, например, идентификатор устройства,
            MAC-адрес, IMEI, локаль (конкретное географическое место, где говорят на данном языке), информация о
            географическом местоположении, IP-адрес, использование приложения, время доступа и сессии, приложения,
            установленные на устройстве или используемые в определенное время на устройстве, а также информацию о том,
            как вы просматриваете рекламу и взаимодействуете с ней, с целью предоставления вам своих услуг, в частности,
            возможность отображать рекламу, предоставление и доставку рекламы, как описано более подробно ниже.

            Политики конфиденциальности наших поставщиков услуг могут включать дополнительные условия и раскрытие
            информации об их методах сбора и использования данных и технологиях отслеживания. Мы рекомендуем вам
            прочитать эти политики конфиденциальности, чтобы узнать больше об их методах сбора и использования данных,
            использовании файлов cookie и других подобных технологий отслеживания.
        </p>


        <h3>Мобильное приложения</h3>
        <p>
            Когда вы используете приложение Սովորել Անգլերեն на мобильной платформе, мы можем регистрировать
            определенную
            информацию и данные, такие как использование приложения, время доступа и сессии, покупки внутри приложения,
            ваш уникальный идентификатор устройства (постоянный / непостоянный), тип аппаратного обеспечения, адрес
            управления доступом к среде (MAC-адрес), международный идентификатор мобильного оборудования (IMEI), версию
            вашей операционной системы («ОС»), имя вашего устройства, адрес электронной почты и ваше местоположение (на
            основе адреса интернет-протокола («IP»)). Эта информация нужна для устранения неисправностей и помогает нам
            понять тенденции использования, а также улучшать и оптимизировать Услуги и ваш пользовательский опыт. Помимо
            этого, она может использоваться и для других целей, таких как персонализированная реклама, как описано в
            других разделах этой Политики.

            Кроме того, чтобы отслеживать, как вы используете нашу Услугу, мы создаем уникальный идентификатор
            пользователя. Этот уникальный идентификатор пользователя связан с информацией вашего профиля, чтобы
            отслеживать приложение, которым вы пользуетесь.

            Мы можем периодически посылать вам push-уведомления через наше мобильное приложения, чтобы отправлять вам
            обновления, достижения и другие уведомления, относящиеся к Услуге, которые могут быть важны для вас. Вы
            можете в любое время отказаться от получения этих сообщений, отключив их с помощью настроек вашего
            устройства.


        </p>

        <h3>Реклама</h3>
        <p>
            В нашем приложении мы можем размещать рекламные объявления как самостоятельно, так и с помощью наших
            партнеров, а также собирать и использовать данные и информацию о вас и вашем устройстве (включая приложения,
            установленные на устройстве или используемые в определенное время на устройстве) в связи с такой рекламой,
            включая ваш просмотр и взаимодействие с любой такой рекламой, для доставки, предоставления возможности
            использования, настройки и оптимизации рекламной информации и информации об акциях, которая более актуальна
            для вас и основана на вашем использовании приложения и устройства, вашего поведения в интернете и интересов
            (это обычно называют персонализированной рекламой на основе поведения пользователя или его интересов). Кроме
            того, в целях отображения персонализированной рекламы мы и наши партнеры можем использовать файлы cookie или
            другие подобные технологии отслеживания, которые необходимы для отслеживания просматриваемых вами материалов
            или местоположения вашего устройства. Политики конфиденциальности наших партнеров могут включать
            дополнительные условия и раскрытие информации об их методах сбора и использования данных и технологиях
            отслеживания. Мы рекомендуем вам прочитать эти политики конфиденциальности, чтобы узнать больше об их
            методах сбора и использования данных, использовании файлов cookie и других подобных технологий отслеживания.

            Рекламодатели на мобильных устройствах и наши сторонние партнеры по рекламе и аналитике иногда собирают и
            используют рекламные идентификаторы для предоставления возможности использования и оптимизации рекламы.
            Идентификаторы рекламы являются непостоянными идентификаторами устройств, такими как идентификатор рекламы
            Android и/или идентификатор Apple для рекламы.

            Эти рекламодатели и сторонние партнеры по рекламе и аналитике могут собирать и использовать данные и
            информацию о вас, например информацию о вашей активности во время сессии в Услуге, просмотре рекламы и
            взаимодействии с ней, идентификатор устройства, MAC-адрес, IMEI, информацию о геолокации и IP-адрес для
            анализа, отслеживания и измерения эффективности рекламы и предоставления персонализированной рекламы.


        </p>
        <h4>Варианты выбора относительно целевой рекламы и использования данных</h4>
        <p>
            Вы можете отказаться от услуг рекламы, подобранной на основе ваших интересов, в мобильном приложени. Для
            этого зайдите в настройки приватности Android или iOS на вашем устройстве и выберите «Ограничить
            отслеживание рекламы» (Apple iOS) или «отказаться от персонализированной рекламы» (Android). Если вы хотите
            изменить свой выбор в отношении использования точных данных о местоположении, вы также можете это сделать с
            помощью настроек мобильного устройства.

        </p>


        <h3>Как мы можем разглашать вашу информацию третьим лицам</h3>
        <p>
            Սովորել Անգլերեն не передает вашу личную информацию, за исключением утвержденных вами или перечисленных ниже
            случаев:

            Սովորել Անգլերեն может привлекать другие компании и частных лиц для оказания услуг от нашего имени. Примеры
            этих услуг включают в себя анализ данных и поддержку клиентов. Эти агенты и поставщики услуг могут иметь
            доступ к вашей личной информации в связи с оказанием услуг для Սովորել Անգլերեն.

            Мы можем разгласить вашу информацию в соответствии с законом, например, чтобы выполнить требования судебной
            повестки, или когда мы считаем, что разглашение информации уместно в соответствии с законом; расследовать
            мошенничество, реагировать на запрос правительства, обеспечивать соблюдение наших прав или воспользоваться
            ими; или защищать права, собственность или безопасность нас или наших пользователей или других лиц. Это
            включает в себя обмен информацией с другими компаниями и организациями для защиты от мошенничества. В
            определенных ситуациях от компании Սովորել Անգլերեն может потребоваться раскрыть личную информацию в ответ
            на
            законные требования со стороны государственных органов или по требованию местных правоохранительных органов.

            Սովորել Անգլերեն может передавать вашу информацию другой компании в связи с любым слиянием, продажей наших
            активов, финансированием или приобретением всего или части нашего бизнеса. О любых изменениях, касающихся
            владения и использования вашей личной информации, вас уведомят по электронной почте и/или разместив
            объявление на наших Сайтах.

            Мы можем предоставлять совокупную или анонимную информацию о вас рекламодателям, издателям, деловым
            партнерам, спонсорам и другим третьим сторонам.
        </p>


        <h3>Доступ к информации, исправление и удаление информации</h3>
        <p>По вашему запросу мы предоставим вам информацию о том, хранится ли у нас какая-либо ваша личная информация.
            Если ваша личная информация изменилась или вы больше не желаете пользоваться нашей Услугой, вы можете
            исправить, обновить, удалить неточности или удалить всю вашу личную информацию, внеся нужные изменения на
            странице настроек учетной записи пользователя либо связавшись с нами. В некоторых случаях мы можем оказаться
            неспособны удалить ваши персональные данные. В таких ситуациях мы сообщим вам об этом с объяснением причин.
            Мы ответим на ваш запрос, связанный с получением доступа к данным, в приемлемые сроки.


        </p>

        <h4>Отказ от получения маркетинговой информации</h4>

        <p>
            Вы можете отказаться от получения рекламных писем, следуя инструкциям в этих письмах и отправив нам
            электронное письмо, используя контактную информацию, предоставленную в этой политике. Если вы откажетесь
            получать рекламные сообщения, мы по-прежнему будем присылать вам письма, не связанные с рекламой, например,
            сообщения о вашей учетной записи или о существующих деловых отношениях.

        </p>


        <h3>Меры безопасности</h3>
        <p>Սովորել Անգլերեն принимает надлежащие меры для защиты вашей информации от несанкционированного доступа или от
            потери, неправомерного использования или изменения третьими лицами.

            Несмотря на то, что мы добросовестно стараемся хранить информацию, собранную в рамках Услуги, в безопасной
            рабочей среде, которая не является общедоступной, мы не можем гарантировать абсолютную безопасность этой
            информации при ее передаче или хранении в наших системах. Кроме того, хотя мы стремимся обеспечить
            целостность и безопасность нашей сети и систем, мы не можем гарантировать, что наши меры безопасности
            помешают сторонним «хакерам» незаконно получить доступ к этой информации. Мы не гарантируем и не заявляем,
            что ваша информация будет защищена от потери, неправомерного использования или изменения третьими лицами.
            Тем не менее, никакой метод передачи данных через Интернет или электронного хранения данных не обеспечивает
            стопроцентную защиту. Таким образом, мы не можем гарантировать абсолютную безопасность данных.

        </p>

        <h3>Хранение данных</h3>
        <p>Ваша информация будет храниться нами в течение всего времени, пока ваша учетная запись остается активной или
            по мере необходимости для предоставления вам Услуг. Мы также обязуемся хранить и использовать вашу
            информацию в соответствии с необходимостью выполнения своих юридических обязательств, урегулирования споров
            и реализации соглашений.

        </p>

        <h3>Наша политика в отношении детей</h3>
        <p>Мы не осуществляем намеренный сбор личной информации у детей в возрасте до 16 лет, не запрашиваем у них такую
            информацию, не направляем им целевую персонализированную рекламу и не позволяем им пользоваться нашими
            Услугами. Если вам меньше 16 лет, не отправляйте нам какую-либо информацию о себе, включая ваше имя, адрес,
            номер телефона или адрес электронной почты. Лицам моложе 16 лет запрещено предоставлять личную информацию.
            Если нам станет известно о том, что нами был осуществлен сбор личной информации от ребенка в возрасте до 16
            лет, мы безотлагательно удалим такую информацию. Если вы считаете, что у нас может быть какая-либо
            информация от ребенка в возрасте до 16 лет или о нем, свяжитесь с нами.
        </p>


        <h3>Международный обмен данными</h3>
        <p>Мы можем передавать информацию, которую мы собираем о вас, аффилированным лицам или другим сторонним лицам за
            пределы вашей страны или юрисдикции в другие страны или юрисдикции по всему миру. Обратите внимание, что
            законы о защите данных в этих странах и юрисдикциях могут отличаться от таковых в вашей собственной
            юрисдикции; мы принимаем меры по обеспечению адекватной защиты передачи информации в Российской Федерации и
            страны места нахождения серверов google play, apple store и amazon и использования, и раскрытия информации о
            вас, в том числе личной информации, как описано в настоящей Политике.

        </p>

        <h3>Изменения в этой политике</h3>
        <p>Мы можем обновлять эту Политику, чтобы она отражала изменения в наших принципах использования данных и
            сохранения конфиденциальности. В случае внесения нами каких-либо существенных изменений мы уведомим вас об
            этом по электронной почте (по адресу, указанному в вашей учетной записи) до того, как такие изменения
            вступят в силу. Мы рекомендуем вам периодически посещать данную страницу, чтобы знакомиться с последней
            информацией о наших принципах сохранения конфиденциальности.

        </p>

        <h3>Контактная информация контроллера данных</h3>
        <p>
            Если у вас возникнут вопросы или сомнения по поводу наших принципов сохранения конфиденциальности, либо если
            вам необходимо решить проблему с вашими личными данными, свяжитесь с нами по адресу:
            support@angleren.online
        </p>
    </div>
};

export default Privacy;
