import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import ChangePassword from "./pages/auth/ChangePassword";
import Privacy from "./pages/Privacy";
import TermsAndConditions from "./pages/TermsAndConditions";

const App = () => {
    return (
        <Routes>
            <Route path="/changePassword" element={<ChangePassword/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
            <Route path="*" element={<Home/>}/>
        </Routes>
    );
}

export default App;
